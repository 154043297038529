import React from 'react';
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import { withStyles } from '@material-ui/core/styles';

const useStyles = () => ({
  fullWidth: {
    width: '100%'
  }
});

const DetectMedia = ({ classes, file }) => {
  let media;

  switch (file && file.type.split('/')[0]) {
    case 'image':
      media = <img className={classes.fullWidth} id={file.id} src={file.url} alt={file.name} />
      break;
    case 'video':
      media =
        <ReactPlayer className={classes.fullWidth} controls url={file.url} />
      break;
    case 'audio':
      media = <ReactAudioPlayer className={classes.fullWidth} controls src={file.url} />
      break;
    default:
      break;
  }

  return (
    <>{media}</>
  );
};

export default withStyles(useStyles)(DetectMedia);
