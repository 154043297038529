import React from 'react';
import { connect } from 'react-redux';
import Quiz from '../components/Quiztake';
import quizActions from '../actions/quiz';

const QuiztakeContainer = ({
  quizData,
  userdata,
  results,
  step,
  course,
  setQuizStep,
  answerQuestion,
  quizComplete,
  setTimerStartDate,
  startDate,
  quizAnswerStorage,
  localQuizData
}) => (
  <Quiz
    quizData={quizData}
    results={results}
    step={step}
    course={course}
    handleQuizStart={setQuizStep}
    handleQuizAnswer={answerQuestion}
    handleQuizComplete={quizComplete}
    handleTimerStartDate={setTimerStartDate}
    startDate={startDate}
    userdata={userdata}
    quizAnswerStorage={quizAnswerStorage}
    localQuizData={localQuizData}
  />
);

const mapStateToProps = state => ({
  quizData: state.quiz.quizData,
  results: state.quiz.results,
  step: state.quiz.step,
  course: state.course,
  startDate: state.quiz.startDate,
  userdata: state.quiz.userdata,
  quizAnswerStorage: state.quiz.quizAnswerStorage,
  localQuizData: state.quiz.localQuizData
});

const mapDispatchToProps = {
  ...quizActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuiztakeContainer);
