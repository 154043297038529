import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import 'url-search-params-polyfill';
import { messages, locales } from '../lang';
import Home from '../containers/Home';
import GetData from '../containers/GetData';

class App extends Component {
  render() {
    const { queryString } = this.props;
    let locale = 'en_US';
    let themeColor = '#ff336d';

    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      locale = urlParams.get('lang');
      themeColor = urlParams.get('themeColor');
    }

    const theme = createMuiTheme({
      palette: {
        primary: {
          main: themeColor,
          contrastText: '#fff'
        },
        secondary: {
          main: '#333',
        },
      },
    });

    return (
      <IntlProvider
        messages={messages[locale]}
        locale={locales[locale]}
      >
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact component={Home} path={'/'} />
            <Route exact component={GetData} path={'/quiz'} />
          </Switch>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  queryString: state.course.queryString,
});

export default hot(module)(connect(mapStateToProps, null)(App));
