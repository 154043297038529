import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = () => ({
  buttonPagination: {
    borderRadius: '1000px',
    height: 50,
    width: 50,
    minWidth: 'auto',
  },
});

const PaginationButton = ({ classes, isDisabled, handleClick, children }) => {
  return (
    <Button
      onClick={handleClick}
      variant="contained"
      color="primary"
      className={classes.buttonPagination}
      disabled={isDisabled}
    >
      { children }
    </Button>
  );
};

export default withStyles(useStyles)(PaginationButton);
