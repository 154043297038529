import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Home from '../components/Home';
import authenticationActions from '../actions/auth';
import courseActions from '../actions/course';

const HomeContainer = ({ authByHash, setQueryString, queryString }) => (
  <Home
    handleAuth={(hash) => authByHash({ hash })}
    handleSetQueryString={(queryString) => setQueryString({ queryString })}
    queryString={queryString}
  />
);

HomeContainer.propTypes = {
  authByHash: PropTypes.func.isRequired,
  setQueryString: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  queryString: state.course.queryString,
});

const mapDispatchToProps = {
  ...authenticationActions,
  ...courseActions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeContainer);
