import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'url-search-params-polyfill';
import courseActions from '../actions/course';
import quizActions from '../actions/quiz';
import Quiztake from '../containers/Quiztake';

const GetData = ({ pageId, quizData, getCourseByUsernameAndSlug, getPageById, queryString }) => {
  useEffect(() => {
    if (queryString == null) {
      console.log("Hello!! You are probably a developer! Please uncomment 'username' & 'slug'");
    }

    // **************** DEV ****************
    const username = 'nadine.appelqvist@coursio.com';
    const slug = 'lyckas-med-dina-tulpaner';
    // **************** DEV ****************

    // **************** PROD ****************
    const urlParams = new URLSearchParams(queryString);
    // const username = urlParams.get('courseOwner');
    // const slug = urlParams.get('courseSlug');
    // **************** PROD ****************

    getCourseByUsernameAndSlug({ username, slug });

  }, [getCourseByUsernameAndSlug, queryString]);

  useEffect(() => {
    if (pageId) {
      getPageById(pageId);
    }

  }, [getPageById, pageId]);


  if (quizData) {
    return <Quiztake />;
  }

  return <h2>Loading...</h2>;
};

GetData.propTypes = {
  getCourseByUsernameAndSlug: PropTypes.func.isRequired,
  getPageById: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  queryString: state.course.queryString,
  pageId: state.course.pageId,
  quizData: state.quiz.quizData,
  timer: state.course.timer,
  isTimer: state.course.isTimer,
});

const mapDispatchToProps = {
  ...courseActions,
  ...quizActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GetData);
