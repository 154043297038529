import types from './types';
import { courseApi } from '../../constants/urls';

const getCourseByUsernameAndSlug = ({ username, slug }) => async (dispatch) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: JSON.parse(localStorage.getItem('token')),
      },
      body: JSON.stringify({
        method: 'findByUsernameAndSlug',
        data: { username, slug },
      }),
    };
    const url = courseApi();
    const response = await fetch(url, options);
    const data = await response.json();

    if (response.status !== 200) {
      throw new Error(data.errMsg);
    }

    await dispatch({
      type: types.GET_COURSE_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_COURSE_DATA_FAIL,
      payload: error.message,
      error: true,
    });
  }
};

const setQueryString = ({ queryString }) => ({
  type: types.SET_QUERY_STRING,
  payload: queryString,
});


export default { getCourseByUsernameAndSlug, setQueryString };
