// Core
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import auth from '../middleware/auth';
import history from '../helpers/history';

// Instrument
import reducer from '../reducers';

// Environment check
const dev = process.env.NODE_ENV === 'development'; // eslint-disable-next-line
const devtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = dev && devtools ? devtools : compose;

const middleware = [thunk, auth, routerMiddleware(history)];

export default createStore(reducer(history), composeEnhancers(applyMiddleware(...middleware)));
