import types from './types';
import { authApi } from '../../constants/urls';

const auth = (body) => async (dispatch) => {
  try {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };

    const url = authApi();
    const response = await fetch(url, options);
    const data = await response.json();

    if (response.status !== 200) {
      throw new Error(data.errMsg);
    }

    await localStorage.setItem('displayName', JSON.stringify(data.data.user.displayName));
    await localStorage.setItem('token', JSON.stringify(data.data.sessionId));
    await localStorage.setItem('user', JSON.stringify(data.data.user));
    await localStorage.setItem('roles', JSON.stringify(data.data.roles));

    await dispatch({
      type: types.AUTHENTICATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.AUTHENTICATION_FAIL,
      payload: error.message,
      error: true,
    });
  }
};

const authByLogin = ({ username, password }) => {
  const body = {
    method: 'login',
    data: {
      identity: username,
      credential: password,
    },
  };

  return auth(body);
};

const authByHash = ({ hash }) => {
  const body = {
    method: 'loginCdk',
    data: { hash },
  };

  return auth(body);
}

export default { authByHash, authByLogin };
