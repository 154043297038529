import React from 'react';
import 'url-search-params-polyfill';
import LoginDev from '../containers/LoginDev';

const Home = ({ handleAuth, handleSetQueryString, queryString }) => {
  if (queryString) {
    const urlParams = new URLSearchParams(queryString);
    const hash = urlParams.get('hash');

    if (hash) {
      // If we have hash, this app is iframed from the old platform,
      // and we can handle SSO auth.
      handleAuth(hash);
    }
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get('hash');

    if (!hash) {
      // Handle auth for development purposes.
      return <LoginDev />
    }

    handleSetQueryString(window.location.search);
  }

  return <h2>Loading...</h2>;
};

export default Home;