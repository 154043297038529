import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import Question from './Question';

const useStyles = () => ({

});

const QuestionSingle = ({ classes, task, prevAnswer, onAnswer }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState('');
  const [isPrefilled, setIsPreFilled] = useState(false);
  const { caption: { main, items }, captionFiles } = task;
  const file = captionFiles && captionFiles.length > 0 ? captionFiles[0] : null;
  const question = main;
  const answers = items;

  // Pre-fill values if question is already answered.
  if (prevAnswer !== null && !isPrefilled) {
    const prevAnswerValue = answers[prevAnswer.body.value[0]];

    setValue(prevAnswerValue);
    setIsPreFilled(true);
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleAnswer = () => {
    const index = answers.indexOf(value);

    if (index < 0) {
      enqueueSnackbar('Please make a choice.', {
        variant: 'warning',
        autoHideDuration: 2000,
      });

      return;
    }

    onAnswer([index]);
  };

  return (
    <Question
      file={file}
      question={question}
      handleAnswer={handleAnswer}
    >
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="question"
          name="question"
          value={value}
          onChange={handleChange}
        >
          {
            answers.map(a => (
              <FormControlLabel
                key={a}
                value={a}
                label={a}
                control={<Radio />}
              />
            ))
          }
        </RadioGroup>
      </FormControl>
    </Question>
  );
};

export default withStyles(useStyles)(QuestionSingle);
