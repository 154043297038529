export const types = {
  GET_PAGE_DATA_FAIL: 'GET_PAGE_DATA_FAIL',
  GET_PAGE_DATA_SUCCESS: 'GET_PAGE_DATA_SUCCESS',
  ANSWER_QUIZ_QUESTION_SUCCESS: 'ANSWER_QUIZ_QUESTION_SUCCESS',
  ANSWER_QUIZ_QUESTION_FAIL: 'ANSWER_QUIZ_QUESTION_FAIL',
  QUIZ_TIMEOUT: 'QUIZ_TIMEOUT',
  QUIZ_COMPLETE_SUCCESS: 'QUIZ_COMPLETE_SUCCESS',
  QUIZ_COMPLETE_FAIL: 'QUIZ_COMPLETE_FAIL',
  SET_QUIZ_STEP: 'SET_QUIZ_STEP',
  SET_TIMER_DATE: 'SET_TIMER_DATE',
}

export const steps = {
  QUIZ_INTRO: 'QUIZ_INTRO',
  QUIZ_MAIN: 'QUIZ_MAIN',
  QUIZ_RESULT: 'QUIZ_RESULT',
}
