import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import PrevResults from '../../containers/PrevResults';

const useStyles = () => ({
  root: {
    textAlign: 'center',
  },
  button: {
    fontWeight: 'bold',
    marginRight: 10
  },
});

const StartView = ({ classes, quizData, settings, handleQuizStart, userdata }) => {
  const [displayPrevResults, setDisplayPrevResults] = useState(false);
  const { messages } = useIntl();
  const ifTaskResults = quizData.map(quiz => {
    return quiz.hasOwnProperty('taskResults') && quiz.taskResults.length > 0
  });
  const ifRetakeOpen = ifTaskResults.some(el => el);

  return (
    <div className={classes.root}>
      <h1>{settings.welcomeText}</h1>
      <p>{settings.descriptionText}</p>

      { settings.repeatable || (!userdata.locked || !ifRetakeOpen)  ? // should add locked prop from coursio3
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleQuizStart}
        >
          {messages.quiz.buttonStart}
        </Button>
        :
        <div>
          {messages.quiz.closed}
        </div>
      }


      {!displayPrevResults &&
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => setDisplayPrevResults(true)}
        >
          {messages.quiz.buttonPrevResults}
        </Button>
      }

      {displayPrevResults &&
        <PrevResults />
      }
    </div>
  );
};

export default withStyles(useStyles)(StartView);
