import { push } from 'connected-react-router';
import authTypes from '../actions/auth/types';

const auth = (store) => (next) => (action) => {
  if (action.type === authTypes.AUTHENTICATION_SUCCESS) {
    if (!action.payload.isError) {
      store.dispatch(push('/quiz'));
    }
  }
  next(action);
};

export default auth;
