import React from 'react';
import { useIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import ResultView from './ResultView';

const useStyles = () => ({
  container: {
    padding: 10,
    margin: '20px auto 60px',
    maxWidth: 450,
  },
  table: {
    background: '#eee',
  },
  tableHeadCell: {
    color: '#aaa',
    textTransform: 'capitalize',
  }
});

const PrevResults = ({ classes, prevResults, quizData, settings }) => {
  const { messages } = useIntl();

  if (!prevResults) {
    return <p>{messages.quiz.prevResultEmpty}</p>
  }

  const rows = prevResults.reverse().map(res => {
    const endTime = zonedTimeToUtc(res.endTime, 'Central European Time');
    const endTimeFormatted = format(new Date(endTime), 'yyyy-MM-dd HH:mm');
    const status = res.status === 'QUIZ_SUCCESS' ? messages.quiz.success : messages.quiz.failed;
    const score = `${res.score}/${res.total}`;

    return (
      <TableRow key={res.duration}>
        <TableCell component="th" scope="row">{endTimeFormatted}</TableCell>
        <TableCell>{status}</TableCell>
        <TableCell align="right">{score}</TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <div className={classes.container}>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a table of old results">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadCell}>{messages.quiz.finished}</TableCell>
                <TableCell className={classes.tableHeadCell}>Status</TableCell>
                <TableCell className={classes.tableHeadCell} align="right">{messages.quiz.score}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <ResultView
        quizData={quizData}
        settings={settings}
        isUserOnStartview={true}
      />
    </>
  );
};

export default withStyles(useStyles)(PrevResults);
