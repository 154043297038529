import types from '../../actions/course/types';
import { snackActions } from '../../helpers/snackbarHelper';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COURSE_DATA_SUCCESS:
      const { settings, toc } = action.payload.data;
      const quizItem = toc.find(item => item.type === 'quiz');
      const pageId = quizItem.pages[0].id;

      return {
        ...state,
        pageId,
        quizSettings: settings.quiz.pages[pageId],
        timer: settings.quiz.timer,
        isTimer: settings.quiz.isTimer,
      };
    case types.GET_COURSE_DATA_FAIL:
      snackActions.error(action.payload)
      return state;
    case types.SET_QUERY_STRING:
      return {
        ...state,
        queryString: action.payload
      };
    default:
      return state;
  }
};
