import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import course from './course';
import quiz from './quiz';

const appReducer = (history) => combineReducers({
    router: connectRouter(history),
    course,
    quiz,
});

export default appReducer;
