import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoginDevForm from '../components/LoginDev';
import authenticationActions from '../actions/auth';

const LoginDev = ({ authByLogin }) => (
  <LoginDevForm
    handleSubmit={(e) => {
      e.preventDefault();

      const username = e.target[0].value;
      const password = e.target[1].value;

      authByLogin({ username, password });
    }}
  />
);

LoginDev.propTypes = {
  authByLogin: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  ...authenticationActions,
};

export default connect(
  null,
  mapDispatchToProps,
)(LoginDev);
