import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useIntl } from 'react-intl';
import DetectMedia from './DetectMedia';

const useStyles = () => ({
  questionContainer: {
    backgroundColor: '#e0e0e0',
    padding: 15,
  },
  answersContainer: {
    padding: '30px 0',
  },
  question: {
    fontWeight: 600,
  },
  buttonAnswer: {
    fontWeight: 'bold',
    width: '100%'
  },
});

const Question = ({
  classes,
  children,
  question,
  handleAnswer,
  file
}) => {
  const { messages } = useIntl();

  return (
    <Paper elevation={0} className={classes.questionContainer}>
      <DetectMedia file={file} />

      <p className={classes.question}>{question}</p>

      <div className={classes.answersContainer}>
        {children}
      </div>

      <Button
        variant="contained"
        color="primary"
        className={classes.buttonAnswer}
        onClick={handleAnswer}
      >
        {messages.quiz.buttonAnswer}
      </Button>
    </Paper>
  );
};

export default withStyles(useStyles)(Question);
