import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import Question from './Question';

const useStyles = () => ({

});

const QuestionMulti = ({ classes, task, prevAnswer, onAnswer }) => {
  const { caption: { main, items }, captionFiles } = task;
  const file = captionFiles && captionFiles.length > 0 ? captionFiles[0] : null;
  const question = main;
  const answers = items;
  const initState = {};
  answers.forEach((_, i) => initState[i] = false); // Create state dynamically for each checkbox.
  const [state, setState] = useState(initState);
  const [isPrefilled, setIsPreFilled] = useState(false);

  // Pre-fill values if question is already answered.
  if (prevAnswer !== null && !isPrefilled) {
    let prevAnswerState = {};
    const prevCheckedIndexes = prevAnswer.body.value;

    answers.forEach((_, i) => {
      prevAnswerState[i] = prevCheckedIndexes.includes(i);
    });

    setState(prevAnswerState);
    setIsPreFilled(true);
  }

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleAnswer = () => {
    const keys = Object.keys(state);
    const indexes = keys
      .filter(key => state[key] === true)
      .map(key => parseInt(key));

    onAnswer(indexes);
  };

  return (
    <Question
      file={file}
      question={question}
      handleAnswer={handleAnswer}
    >
      <FormControl component="fieldset">
        <FormGroup>
          {
            answers.map((a, i) => {
              const name = i.toString();

              return (
                <FormControlLabel
                  key={a}
                  control={<Checkbox checked={state[i]} onChange={handleChange} name={name} />}
                  label={a}
                />
              )
            })
          }
        </FormGroup>
      </FormControl>
    </Question>
  );
};

export default withStyles(useStyles)(QuestionMulti);
