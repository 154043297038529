import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

const useStyles = () => ({
  root: {
    padding: '10px 5px',
  },
  formItem: {
    margin: '5px 0',
    width: '100%',
  }
});

const LoginDev = ({ classes, handleSubmit }) => {
  return (
    <form
      onSubmit={handleSubmit}
      className={classes.root}
      noValidate
    >
      <Input
        id="email-input"
        className={classes.formItem}
        placeholder="Email"
        type="email"
      />

      <Input
        id="password-input"
        className={classes.formItem}
        placeholder="Password"
        type="password"
      />

      <Button
        type="submit"
        variant="contained"
        className={classes.formItem}
      >
        LOGIN
      </Button>
    </form>
  );
};

export default withStyles(useStyles)(LoginDev);
