export const apiPath = 'https://t-api.s.coursio.com/';

const applyApiPathTo = ({ path }) => {
  switch (path) {
    case 'api/auth':
      return `${apiPath}${path}`;
    case 'api/course':
      return `${apiPath}${path}`;
    case 'api/page':
      return `${apiPath}${path}`;
    case 'api/task':
      return `${apiPath}${path}`;
    case 'api/quiz':
      return `${apiPath}${path}`;
    default:
      break;
  }
};

// api
export const authApi = () => applyApiPathTo({ path: 'api/auth' });
export const courseApi = () => applyApiPathTo({ path: 'api/course' });
export const pageApi = () => applyApiPathTo({ path: 'api/page' });
export const taskApi = () => applyApiPathTo({ path: 'api/task' });
export const quizApi = () => applyApiPathTo({ path: 'api/quiz' });
