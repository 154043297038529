import { types } from './types';
import { pageApi, taskApi, quizApi } from '../../constants/urls';

const getOptions = (method, data) => {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      token: JSON.parse(localStorage.getItem('token')),
    },
    body: JSON.stringify({ method, data }),
  };
}

const getPageById = (id) => async (dispatch) => {
  try {
    const options = getOptions('findById', { id });
    const url = pageApi();
    const response = await fetch(url, options);
    const data = await response.json();

    if (response.status !== 200) {
      throw new Error(data.errMsg);
    }

    await dispatch({
      type: types.GET_PAGE_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: types.GET_PAGE_DATA_FAIL,
      payload: error.message,
      error: true,
    });
  }
};

const answerQuestion = (answerData) => async (dispatch) => {
  try {
    console.log('answerData', answerData)
    // const options = getOptions('answer', answerData);
    // const url = taskApi();
    // const response = await fetch(url, options);
    // const data = await response.json();
    //
    // if (response.status !== 200) {
    //   throw new Error(data.errMsg);
    // }

    await dispatch({
      type: types.ANSWER_QUIZ_QUESTION_SUCCESS,
      payload: answerData,
    });
  } catch (error) {
    dispatch({
      type: types.ANSWER_QUIZ_QUESTION_FAIL,
      payload: error.message,
      error: true,
    });
  }
};

const quizComplete = (pageId, answerData, quizData) => async (dispatch) => {
  try {
    console.log('answerData', answerData)
    let readyToSend = answerData;
    if (answerData.length !== quizData.length) {
      quizData.forEach((el, i) => {
        const { taskId, pageModuleId: pmid } = quizData[i];
        const data = { taskId, pmid, body: {value: []} };

        if (!answerData[i]) {
          readyToSend[i] = data
        }
      })
    }
    console.log('readyToSend', readyToSend)
    const bulkOptions = getOptions('answerBulk', readyToSend);
    const taskUrl = taskApi();
    const taskResponse = await fetch(taskUrl, bulkOptions);
    const taskData = await taskResponse.json();

    if (taskResponse.status !== 200) {
      throw new Error(taskData.errMsg);
    }

    const completeOptions = getOptions('complete', { pageId });
    const quizUrl = quizApi();
    const quizResponse = await fetch(quizUrl, completeOptions);
    const newQuizData = await quizResponse.json();

    if (quizResponse.status !== 200) {
      throw new Error(newQuizData.errMsg);
    }

    await dispatch({
      type: types.QUIZ_COMPLETE_SUCCESS,
      payload: newQuizData,
    });
  } catch (error) {
    dispatch({
      type: types.QUIZ_COMPLETE_FAIL,
      payload: error.message,
      error: true,
    });
  }
};

const setQuizStep = step => ({
  type: types.SET_QUIZ_STEP,
  payload: step,
});

const setTimerStartDate = date => ({
  type: types.SET_TIMER_DATE,
  payload: date
})

export default { getPageById, answerQuestion, quizComplete, setQuizStep, setTimerStartDate };
