import React from 'react';
import { connect } from 'react-redux';
import PrevResults from '../components/Quiztake/PrevResults';

const PrevResultsContainer = ({
  prevResults,
  quizData,
  settings,
}) => (
  <PrevResults
    prevResults={prevResults}
    quizData={quizData}
    settings={settings}
  />
);

const mapStateToProps = state => ({
  prevResults: state.quiz.prevResults,
  quizData: state.quiz.quizData,
  settings: state.course.quizSettings
});

export default connect(
  mapStateToProps,
  null,
)(PrevResultsContainer);
