import svSE from './sv_SE';
import enUS from './en_US';

export const messages = {
  en_US: enUS,
  sv_SE: svSE,
};

export const locales = {
  en_US: 'en-US',
  sv_SE: 'sv-SE',
};
